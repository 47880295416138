<template>
  <div class="page-grid">
    <v-container class="category-block-header">
      <category-title :category="category" />
    </v-container>

    <template v-for="(row, rowIndex) in rows">
      <v-container v-if="row.container" :key="rowIndex">
        <v-row :class="`row-number-${rowIndex}`">
          <v-col
            v-for="(column, columnIndex) in row.cols"
            :key="columnIndex"
            :cols="getColData(column, 'xs')"
            :sm="getColData(column, 'sm')"
            :md="getColData(column, 'md')"
            :lg="getColData(column, 'lg')"
            :xl="getColData(column, 'xl')"
            :class="`col-number-${columnIndex}`"
          >
            <category-block
              :config="getCategoryBlockConfig(column.position)"
              :container="false"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-row v-else :class="`row-number-${rowIndex}`" :key="rowIndex">
        <v-col
          v-for="(column, columnIndex) in row.cols"
          :key="columnIndex"
          :cols="getColData(column, 'xs')"
          :sm="getColData(column, 'sm')"
          :md="getColData(column, 'md')"
          :lg="getColData(column, 'lg')"
          :xl="getColData(column, 'xl')"
          :class="`col-number-${columnIndex}`"
        >
          <category-block
            :config="getCategoryBlockConfig(column.position)"
            :container="false"
          />
        </v-col>
      </v-row>
    </template>

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions } from "vuex";

import split from "lodash/split";
import get from "lodash/get";

export default {
  name: "PageGrid",
  mixins: [category, deliveryReactive, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  computed: {
    rows() {
      return get(this.category, "metaData.template_model.GRID_CONFIG.rows");
    }
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),
    getColData(column, fieldPath) {
      return get(column, fieldPath, 12);
    },
    getCategoryBlockConfig(positionCodInt) {
      let blockFound = null;
      if (this.category.categoryBlocks.length > 0) {
        blockFound = this.category.categoryBlocks.filter(
          block => block.templateBlock.codInt == positionCodInt
        );
        if (blockFound.length > 0) {
          let f = blockFound[0];
          return f;
        }
      }

      return null;
    },
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    }
  }
};
</script>
